const regexInput = (value) => {
  // eslint-disable-next-line
  const regex = /[$\{\}<>]/;
  return !regex.test(value);
};

const regexDescription = (value) => {
  // eslint-disable-next-line
  const regex = /[\{\}<>]/;
  return !regex.test(value);
};

const regexEmail = (value) => {
  const regex = /^[A-Za-z0-9@._]+$/;
  return regex.test(value);
};

const regexUrl = (value) => {
  const regex = /^[A-Za-z0-9@./]+$/;
  return regex.test(value);
};

const regexPassword = (value) => {
  // eslint-disable-next-line
  const regex = /[$\{\}<>]/;
  return !regex.test(value);
};

export { regexInput, regexDescription, regexEmail, regexUrl, regexPassword };
